export const PALETTE = [
  "#EB5757",
  "#F2994A",
  "#F2C94C",
  "#6FCF97",
  "#56CCF2",
  "#2F80ED",
  "#9B51E0",
  "#FFF",
  "#111",
];
